import React from 'react';
import './App.css';
import {loginRouters} from "./route";
import {useRoutes} from "react-router-dom";
import {Inspector} from "react-dev-inspector";

const InspectorWrapper = process.env.NODE_ENV === "development" ? Inspector : React.Fragment;

function App() {
  const loginElement = useRoutes(loginRouters);
    console.log("platform: ", window.navigator.platform)
  return (
    <InspectorWrapper keys={["control", "shift", "z"]}>
      <div>{loginElement}</div>
    </InspectorWrapper>
  );
}

export default App;
